.info__container {
  width: 10rem;
  height: 10rem;
  z-index: 20;
  background: white;
  color: #202020;
  border-radius: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.info__container__heading {
  font-weight: bold;
  font-size: 1rem;
  margin-bottom: .4rem;
}

.info__container>ul {
  list-style: none;
}

.info__container__ul>li {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.info__container__title {
  font-weight: bold;
  padding-bottom: .1rem;
}