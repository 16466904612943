.footer {
  width: 100%;
  height: 3.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  border-top: 2px solid #202020;
  background-color: white;
}

.footer__link {
  text-decoration: none;
  font-weight: bold;
  color: var(--purple);
}

@media(max-width: 900px) {

  .footer__link,
  .footer__text {
    font-size: .8rem
  }
}