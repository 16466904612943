@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&family=Oswald:wght@400;700&display=swap);
:root {
  --red: #d32f2fc2;
  --yellow: #fbc02dc2;
  --orange: #f57c00c2;
  --purple: #512da8c2;
  --green: #388e3cc2;
  --gray: #616161c2;
  --blue: #0288D1;
}

html {
  height: 100%;
}

p,
ul,
span {
  margin: 0;
  padding: 0
}

body {
  margin: 0;
  min-height: 100%;
  position: relative;
  padding: 3.3rem 0 3.5rem 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
.worldStatus_table__2mEVo {
    width: 90%;
    max-width: 1400px;
    margin: auto;
    border: none;
    border-collapse: collapse;
    text-align: right;
}

.worldStatus_table__inner__container__1cUaz {
    display: flex;
}

.worldStatus_table__header__2ezlQ {
    margin-top: 1rem;
    height: 20vh;
    width: auto;
}

.worldStatus_table__header__container__3e8Jz {
    width: 10%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
}

.worldStatus_table__info__36JBS {
    width: 100%;
    padding: 1rem 0;
    background-color: var(--gray);
    color: white;
    text-align: center;
}

.worldStatus_table__2mEVo th {
    padding: .4rem 1.5rem;
    text-align: right;
    background-color: #eeeeee;
    cursor: pointer;
    white-space: nowrap;
}

.worldStatus_table__2mEVo td {
    padding: .3rem 1.5rem;
    white-space: nowrap;
}

.worldStatus_table__2mEVo td:first-child {
    font-weight: bold;
}

.worldStatus_table__2mEVo tr:nth-child(2n) {
    background-color: #f5f5f5;
}

.worldStatus_table__2mEVo tbody tr:hover {
    background-color: #464646;
    color: white;
    font-size: 1.2rem;
    transition: font-size 0.1s ease-in;
}

.worldStatus_table__2mEVo tbody td:hover {
    padding: .6rem 1.5rem;
    transition: padding 0.1s ease-in;
}

.worldStatus_table__container__2jxA2 {
    display: grid;
    flex-direction: column;
    width: 100%;
}

.worldStatus_active_case__TIkWN {
    background-color: var(--red) !important;
    color: white;
}

.worldStatus_active_death__tnxNU {
    background-color: var(--gray) !important;
    color: white;
}

.worldStatus_active_recovered__3Jl2L {
    background-color: var(--green) !important;
    color: white;
}

.worldStatus_active_active__325hg {
    background-color: var(--yellow) !important;
    color: white;
}

.worldStatus_active_critical__2AzkV {
    background-color: var(--orange) !important;
    color: white;
}

.worldStatus_active_casesPerMillion__j7Xyy {
    background-color: var(--purple) !important;
    color: white;
}

@media(max-width:1400px) and (min-width: 1130px) {
    .worldStatus_table__2mEVo {
        width: 70%;
        max-width: 1000px;
    }

    .worldStatus_table__2mEVo th {
        padding: .2rem .6rem;
        font-size: 1rem;
    }

    .worldStatus_table__2mEVo td {
        padding: .2rem .6rem;
        font-size: 1rem;
    }
}

@media(max-width:1130px) and (min-width: 950px) {
    .worldStatus_table__2mEVo {
        width: 70%;
        max-width: 700px;
    }

    .worldStatus_table__2mEVo th {
        padding: .9rem .3rem;
        font-size: .8rem;
    }

    .worldStatus_table__2mEVo td {
        padding: .1rem .3rem;
        font-size: .8rem;
    }

    .worldStatus_table__header__2ezlQ {
        margin-top: 1rem;
        height: 15vh;
        width: auto;
    }
}

@media(max-width: 950px) and (min-width: 500px) {
    .worldStatus_table__2mEVo {
        width: 90%;
        max-width: 700px;
    }

    .worldStatus_table__2mEVo th {
        padding: .6rem .3rem;
        font-size: .8rem;
    }

    .worldStatus_table__2mEVo td {
        padding: .1rem .3rem;
        font-size: .8rem;
    }

    .worldStatus_table__header__2ezlQ {
        margin-top: 1rem;
        height: 10vh;
        width: auto;
    }

    .worldStatus_table__info__36JBS {
        padding: .8rem 0;
        font-size: .8rem
    }
}

@media(max-width: 500px) {
    .worldStatus_table__2mEVo {
        width: 90%;
        max-width: 450px;
    }

    .worldStatus_table__2mEVo th {
        padding: .4rem .2rem;
        font-size: .7rem;
    }

    .worldStatus_table__2mEVo td {
        padding: .1rem .3rem;
        font-size: .7rem;
    }

    .worldStatus_table__header__2ezlQ {
        margin-top: 1rem;
        height: 8vh;
        width: auto;
    }

    .worldStatus_table__info__36JBS {
        padding: .6rem 0;
        font-size: .7rem
    }
}
.countryMarker_marker__jHt4o {
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 0.8rem;
}

.countryMarker_inverted__1FXjx {
    background-color: var(--blue) !important;
}

.countryMarker_small__1z9IY {
    height: 20px;
    width: 20px;
    margin-left: -10px;
    margin-top: -10px;
}

.countryMarker_medium__2f4pm {
    height: 30px;
    width: 30px;
    margin-left: -15px;
    margin-top: -15px;
}

.countryMarker_large__iifAC {
    height: 40px;
    width: 40px;
    margin-left: -20px;
    margin-top: -20px;
}

.countryMarker_x_large__2GxTp {
    height: 50px;
    width: 50px;
    margin-left: -25px;
    margin-top: -25px;
}

.countryMarker_red__94eiR {
    background-color: var(--red);
    border: 1px solid white;
    color: white;
}

.countryMarker_gray__ohlS3 {
    background-color: var(--gray);
    border: 1px solid white;
    color: white;
}

.countryMarker_green__3aXky {
    background-color: var(--green);
    border: 1px solid white;
    color: white;
}

.countryMarker_yellow__1-hQ8 {
    background-color: var(--yellow);
    border: 1px solid white;
    color: white;
}

.countryMarker_orange__2oWv2 {
    background-color: var(--orange);
    border: 1px solid white;
    color: white;
}

.countryMarker_purple__P2KCw {
    background-color: var(--purple);
    border: 1px solid white;
    color: white;
}
.infoBox_info__container__1OgbG {
  width: 10rem;
  height: 10rem;
  z-index: 20;
  background: white;
  color: #202020;
  border-radius: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.infoBox_info__container__heading__34qWb {
  font-weight: bold;
  font-size: 1rem;
  margin-bottom: .4rem;
}

.infoBox_info__container__1OgbG>ul {
  list-style: none;
}

.infoBox_info__container__ul__1YllQ>li {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.infoBox_info__container__title__12WeC {
  font-weight: bold;
  padding-bottom: .1rem;
}
.usStatus_table__3hxrU {
    width: 90%;
    max-width: 1400px;
    margin: auto;
    border: none;
    border-collapse: collapse;
    text-align: right;
}

.usStatus_table__inner__container__LmGAt {
    display: flex;
}

.usStatus_table__header__1CxaL {
    margin-top: 1rem;
    height: 20vh;
    width: auto;
}

.usStatus_table__header__container__3NfI4 {
    width: 10%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
}

.usStatus_table__info__3FZRT {
    width: 100%;
    padding: 1rem 0;
    background-color: var(--gray);
    color: white;
    text-align: center;
}

.usStatus_table__3hxrU th {
    padding: .4rem 1.5rem;
    text-align: right;
    background-color: #eeeeee;
    cursor: pointer;
    white-space: nowrap;
}

.usStatus_table__3hxrU td {
    padding: .3rem 1.5rem;
    white-space: nowrap;
}

.usStatus_table__3hxrU td:first-child {
    font-weight: bold;
}

.usStatus_table__3hxrU tr:nth-child(2n) {
    background-color: #f5f5f5;
}

.usStatus_table__3hxrU tbody tr:hover {
    background-color: #464646;
    color: white;
    font-size: 1.2rem;
    transition: font-size 0.1s ease-in;
}

.usStatus_table__3hxrU tbody td:hover {
    padding: .6rem 1.5rem;
    transition: padding 0.1s ease-in;
}

.usStatus_table__container__1BY2B {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
}

.usStatus_active_case__syGFX {
    background-color: var(--red) !important;
    color: white;
}

.usStatus_active_death__3CGoL {
    background-color: var(--gray) !important;
    color: white;
}

.usStatus_active_recovered__28KX8 {
    background-color: var(--green) !important;
    color: white;
}

.usStatus_active_active__1_Uj5 {
    background-color: var(--yellow) !important;
    color: white;
}

.usStatus_active_critical__LkV0o {
    background-color: var(--orange) !important;
    color: white;
}

.usStatus_active_casesPerMillion__UX5Ja {
    background-color: var(--purple) !important;
    color: white;
}

@media(max-width:1000px) and (min-width: 500px) {
    .usStatus_table__3hxrU {
        width: 80%;
        max-width: 700px;
    }

    .usStatus_table__3hxrU th {
        padding: .9rem .3rem;
        font-size: .8rem;
    }

    .usStatus_table__3hxrU td {
        padding: .1rem .3rem;
        font-size: .8rem;
    }

    .usStatus_table__header__1CxaL {
        margin-top: 1rem;
        height: 15vh;
        width: auto;
    }
}
.stateMarker_marker__1X3_r {
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 0.8rem;
}

.stateMarker_inverted__32TJJ {
    background-color: var(--blue) !important;
}

.stateMarker_small__ntfNe {
    height: 20px;
    width: 20px;
    margin-left: -10px;
    margin-top: -10px;
}

.stateMarker_medium__3d7Bx {
    height: 30px;
    width: 30px;
    margin-left: -15px;
    margin-top: -15px;
}

.stateMarker_large__1ju-O {
    height: 40px;
    width: 40px;
    margin-left: -20px;
    margin-top: -20px;
}

.stateMarker_x_large__2zidw {
    height: 50px;
    width: 50px;
    margin-left: -25px;
    margin-top: -25px;
}

.stateMarker_red__hH4Tg {
    background-color: var(--red);
    border: 1px solid white;
    color: white;
}

.stateMarker_gray__2Eyll {
    background-color: var(--gray);
    border: 1px solid white;
    color: white;
}

.stateMarker_green__YxiMM {
    background-color: var(--green);
    border: 1px solid white;
    color: white;
}

.stateMarker_yellow__1QNSh {
    background-color: var(--yellow);
    border: 1px solid white;
    color: white;
}

.stateMarker_orange__39GBV {
    background-color: var(--orange);
    border: 1px solid white;
    color: white;
}

.stateMarker_purple__3xtZQ {
    background-color: var(--purple);
    border: 1px solid white;
    color: white;
}
.stateInfoBox_info__container__2O2Dh {
  width: 9rem;
  height: 9rem;
  z-index: 20;
  background: white;
  color: #202020;
  border-radius: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.stateInfoBox_info__container__heading__2HYGI {
  font-weight: bold;
  font-size: 1rem;
  margin-bottom: .1rem;
}

.stateInfoBox_info__container__2O2Dh>ul {
  list-style: none;
}

.stateInfoBox_info__container__ul__2NqBn>li {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.stateInfoBox_info__container__title__2Wpht {
  font-weight: bold;
  padding-bottom: .1rem;
}
.navbar_invisible__1D9Zy {
  display: none;
}

.navbar_visible__3AKFk {
  display: inline-block;
}

.navbar_navbar__q1H5d {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 2px solid #202020;
  height: 3.3rem;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 10;
  background-color: white;
}

.navbar_navbar__container__31Zlf {
  display: flex;
  flex-direction: row;
  padding-right: 1rem;
  position: relative;
}

.navbar_navbar__item__1M4-8 {
  padding: 1rem;
  text-decoration: none;
  color: #303030;
}

.navbar_navbar__header__180Dy {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
  font-family: "Oswald", "sans-serif";
  font-size: 1.6rem;
}

.navbar_navbar__header__link__7mBsb {
  text-decoration: none;
  color: #202020;
}

@media (max-width: 850px) {
  .navbar_navbar__header__180Dy {
    font-size: 1.2rem;
  }

  .navbar_navbar__item__1M4-8 {
    font-size: 0.8rem;
    padding: 1.15rem 0.5rem;
  }
}

@media (max-width: 250px) {
  .navbar_navbar__header__180Dy {
    font-size: .9rem;
  }
}
.burgerMenu_menu__icon__2wdjK {
  font-size: 1.2rem;
  padding: 1.15rem 0.5rem;
}

.burgerMenu_menu__icon__2wdjK:hover {
  cursor: pointer
}

.burgerMenu_navbar__side__x__2qrN_ {
  display: flex;
  justify-content: flex-end;
}

.burgerMenu_navbar__side__container__3PIVe {
  width: 15rem;
  height: 100vh;
  background-color: #cfcfcf;
  z-index: 30;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0
}

.burgerMenu_backdrop__Adrsz {
  width: 100%;
  height: 100%;
  z-index: 29;
  background-color: var(--gray);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.burgerMenu_navbar__container__1SKRE {
  display: flex;
  flex-direction: column;
}

.burgerMenu_navbar__item__2VO2O {
  text-decoration: none;
  padding: 1rem 2rem;
  color: #202020;
  font-size: 1.2rem;
}
.footer_footer__1lZIc {
  width: 100%;
  height: 3.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  border-top: 2px solid #202020;
  background-color: white;
}

.footer_footer__link__16Kx5 {
  text-decoration: none;
  font-weight: bold;
  color: var(--purple);
}

@media(max-width: 900px) {

  .footer_footer__link__16Kx5,
  .footer_footer__text__g-OYd {
    font-size: .8rem
  }
}
.articles_article__main__container__YGVIx {
  display: flex;
  flex-direction: column;
}

.articles_article__container__3U9nW {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 1rem;
  background: url(/static/media/tree.80812979.jpg) no-repeat center center fixed;
  /* background-size: cover; */
}

.articles_article__info__2zSdD {
  width: 100%;
  padding: 1rem;
  background-color: var(--gray);
  text-align: center;
  color: white;
  font-weight: bold
}

.articles_article__main__header__1kT0v {
  max-width: 70%;
  padding: 1rem;
  font-family: "Oswald", "sans-serif";
  font-weight: bold;
  font-size: 2rem;
  align-self: center;
}

@media (max-width: 900px) {
  .articles_article__info__2zSdD>p {
    font-size: .7rem;
  }
}
.article_card__39_S7 {
  width: 70%;
  padding: 1.5rem;
  margin: .5rem;
  border: 1px solid #202020;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  box-shadow: 5px 5px 8px #888888;
  background-color: #ffffffef;
}

.article_card__journal__2QG5s {
  font-size: 0.8rem;
  font-style: italic;
}

.article_card__header__3238J {
  font-weight: bold;
  padding-bottom: .3rem 0 .5rem 0;
}

.article_card__pubdate__3IfDs {
  font-weight: bold;
}

.article_card__link__2EAGY {
  text-decoration: none;
  color: #202020;
}

.article_card__authors__gBHib {
  font-size: 0.8rem
}
.landing_mainContainer__2oU8n {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: url(/static/media/world.bde89f90.jpg) no-repeat center center/cover;
}

.landing_innerContainer__24e8r {
  max-width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 6rem 4rem;
  border: 1px solid var(--gray);
  border-radius: 10px;
  box-shadow: 5px 4px 8px 4px #0000004d;
  background: rgba(255, 255, 255, 0.795);
}

.landing_innerContainer__24e8r>p {
  font-size: 1.2rem;
  margin-bottom: .8rem;
}

.landing_countryName__iwKSz {
  color: var(--blue);
  font-size: 1.5rem;
  font-weight: bold;
}

/* .countries {
  display: inline-block;
  font-size: 1.1rem;
  font-weight: bold;
  color: #444;
  max-width: 12rem;
  border: 1px solid #aaa;
  box-shadow: 0 1px 0 1px rgba(0, 0, 0, .04);
  border-radius: .5em;
} */

.landing_latestData__1XYru {
  margin-top: .8rem
}


span.landing_death__1NB7M {
  color: var(--red);
  font-size: 1.5rem;
  font-weight: bold;
}

span.landing_case__dNSHt {
  color: var(--green);
  font-size: 1.5rem;
  font-weight: bold;
}

@media (max-width: 950px) {

  span.landing_death__1NB7M,
  span.landing_case__dNSHt,
  .landing_countryName__iwKSz {
    font-size: 1.2rem
  }

  .landing_innerContainer__24e8r>p {
    font-size: 1rem;
    margin-bottom: .8rem
  }

  .landing_innerContainer__24e8r {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 4rem 3rem;
  }
}
