@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&family=Oswald:wght@400;700&display=swap');

:root {
  --red: #d32f2fc2;
  --yellow: #fbc02dc2;
  --orange: #f57c00c2;
  --purple: #512da8c2;
  --green: #388e3cc2;
  --gray: #616161c2;
  --blue: #0288D1;
}

html {
  height: 100%;
}

p,
ul,
span {
  margin: 0;
  padding: 0
}

body {
  margin: 0;
  min-height: 100%;
  position: relative;
  padding: 3.3rem 0 3.5rem 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}