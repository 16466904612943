.card {
  width: 70%;
  padding: 1.5rem;
  margin: .5rem;
  border: 1px solid #202020;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  box-shadow: 5px 5px 8px #888888;
  background-color: #ffffffef;
}

.card__journal {
  font-size: 0.8rem;
  font-style: italic;
}

.card__header {
  font-weight: bold;
  padding-bottom: .3rem 0 .5rem 0;
}

.card__pubdate {
  font-weight: bold;
}

.card__link {
  text-decoration: none;
  color: #202020;
}

.card__authors {
  font-size: 0.8rem
}